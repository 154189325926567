/**
 * @generated SignedSource<<9508777ce2f7b660bed6fe1420c67492>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Orders_data$data = {
  readonly criteriaTakerAsset?: {
    readonly decimals: number | null;
    readonly isDelisted: boolean;
    readonly ownedQuantity: string | null;
    readonly relayId: string;
    readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_asset" | "AcceptOfferDisabledWarningIcon_asset" | "asset_url">;
  };
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"Orders_orders">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "Orders_data";
};
export type Orders_data$key = {
  readonly " $data"?: Orders_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Orders_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "orders"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": "QXNzZXRUeXBlOi0x",
      "kind": "LocalArgument",
      "name": "criteriaTakerAssetId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeMaker"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "expandedMode"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "filterByOrderRules"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeCriteriaOrders"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeCriteriaTakerAsset"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeInvalidBids"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBid"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isExpired"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInactive"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSingleAsset"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isValid"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maker"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "makerArchetype"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "makerAssetBundle"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "makerAssetIsPayment"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "takerArchetype"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "takerAssetBundle"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "takerAssetCollections"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "takerAssetIsOwnedBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "takerAssetIsPayment"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./OrdersPaginationQuery.graphql')
    }
  },
  "name": "Orders_data",
  "selections": [
    {
      "condition": "includeCriteriaTakerAsset",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "criteriaTakerAsset",
          "args": [
            {
              "kind": "Variable",
              "name": "asset",
              "variableName": "criteriaTakerAssetId"
            }
          ],
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "identity",
                  "value": {}
                }
              ],
              "kind": "ScalarField",
              "name": "ownedQuantity",
              "storageKey": "ownedQuantity(identity:{})"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimals",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDelisted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChainType",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "identifier",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AcceptOfferButton_asset"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AcceptOfferDisabledWarningIcon_asset"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": "orders",
      "args": [
        {
          "kind": "Variable",
          "name": "excludeMaker",
          "variableName": "excludeMaker"
        },
        {
          "kind": "Variable",
          "name": "filterByOrderRules",
          "variableName": "filterByOrderRules"
        },
        {
          "kind": "Variable",
          "name": "includeCriteriaOrders",
          "variableName": "includeCriteriaOrders"
        },
        {
          "kind": "Variable",
          "name": "includeInvalidBids",
          "variableName": "includeInvalidBids"
        },
        {
          "kind": "Variable",
          "name": "isExpired",
          "variableName": "isExpired"
        },
        {
          "kind": "Variable",
          "name": "isInactive",
          "variableName": "isInactive"
        },
        {
          "kind": "Variable",
          "name": "isValid",
          "variableName": "isValid"
        },
        {
          "kind": "Variable",
          "name": "maker",
          "variableName": "maker"
        },
        {
          "kind": "Variable",
          "name": "makerArchetype",
          "variableName": "makerArchetype"
        },
        {
          "kind": "Variable",
          "name": "makerAssetBundle",
          "variableName": "makerAssetBundle"
        },
        {
          "kind": "Variable",
          "name": "makerAssetIsPayment",
          "variableName": "makerAssetIsPayment"
        },
        {
          "kind": "Variable",
          "name": "sortAscending",
          "variableName": "sortAscending"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        },
        {
          "kind": "Variable",
          "name": "takerArchetype",
          "variableName": "takerArchetype"
        },
        {
          "kind": "Variable",
          "name": "takerAssetBundle",
          "variableName": "takerAssetBundle"
        },
        {
          "kind": "Variable",
          "name": "takerAssetCollections",
          "variableName": "takerAssetCollections"
        },
        {
          "kind": "Variable",
          "name": "takerAssetIsOwnedBy",
          "variableName": "takerAssetIsOwnedBy"
        },
        {
          "kind": "Variable",
          "name": "takerAssetIsPayment",
          "variableName": "takerAssetIsPayment"
        }
      ],
      "concreteType": "OrderV2TypeConnection",
      "kind": "LinkedField",
      "name": "__Orders_orders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2TypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "expandedMode",
                      "variableName": "expandedMode"
                    },
                    {
                      "kind": "Variable",
                      "name": "includeCriteriaTakerAsset",
                      "variableName": "includeCriteriaTakerAsset"
                    },
                    {
                      "kind": "Variable",
                      "name": "isBid",
                      "variableName": "isBid"
                    },
                    {
                      "kind": "Variable",
                      "name": "isSingleAsset",
                      "variableName": "isSingleAsset"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "Orders_orders"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a74fd3f719e9a780b7d67dfb611244d9";

export default node;
