import React from "react"
import type { UncontrolledModalProps } from "@/design-system/Modal"
import {
  MultiStepModal,
  MultiStepModalProps,
} from "@/design-system/Modal/MultiStepModal.react"

type Props = Pick<MultiStepModalProps, "onClose" | "onPrevious" | "disabled"> &
  Pick<
    UncontrolledModalProps,
    "trigger" | "initiallyOpen" | "focusFirstFocusableElement"
  > & {
    disabled?: boolean
  } & Omit<TradeMultiModalContentProps, "onClose">

type TradeMultiModalContentProps = {
  renderMainModal: (onClose: () => unknown) => JSX.Element | null
  onClose: () => unknown
}

export const TradeMultiModal = ({
  trigger,
  renderMainModal,
  ...multiStepModalProps
}: Props) => {
  return (
    <MultiStepModal
      size="large"
      trigger={open => trigger(open)}
      {...multiStepModalProps}
    >
      {onClose => renderMainModal(onClose)}
    </MultiStepModal>
  )
}
