import React from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { AssetOfferModal_asset$key } from "@/lib/graphql/__generated__/AssetOfferModal_asset.graphql"
import { AssetOfferModalQuery } from "@/lib/graphql/__generated__/AssetOfferModalQuery.graphql"
import { OfferModalProps, OfferModal } from "./OfferModal.react"

type AssetOfferModalProps = Pick<
  OfferModalProps,
  "initialQuantity" | "onClose" | "onOrderCreated"
> & {
  asset: AssetOfferModal_asset$key
}

const LazyAssetOfferModal = ({
  asset: assetDataKey,
  ...restProps
}: AssetOfferModalProps) => {
  const { wallet } = useWallet()

  const { relayId: assetRelayId, chain } = useFragment(
    graphql`
      fragment AssetOfferModal_asset on AssetType {
        relayId
        chain {
          identifier
        }
      }
    `,
    assetDataKey,
  )

  const {
    getAccount: account,
    tradeLimits,
    asset,
  } = useLazyLoadQuery<AssetOfferModalQuery>(
    graphql`
      query AssetOfferModalQuery(
        $address: AddressScalar!
        $asset: AssetRelayID!
        $chain: ChainScalar!
      ) {
        asset(asset: $asset) {
          ...OfferModal_asset @arguments(chain: $chain)
          tradeSummary {
            ...OfferModal_tradeSummary
          }
        }
        getAccount(address: $address) {
          ...OfferModal_account
        }
        tradeLimits(chain: $chain) {
          ...OfferModal_tradeLimits
        }
      }
    `,
    {
      chain: chain.identifier,
      asset: assetRelayId,
      address: wallet.getActiveAddressStrict(),
    },
  )

  return (
    <OfferModal
      account={account}
      asset={asset}
      collection={null}
      tradeDataKey={asset.tradeSummary}
      tradeLimitsDataKey={tradeLimits}
      {...restProps}
    />
  )
}

export const AssetOfferModal = (props: AssetOfferModalProps) => {
  return (
    <SsrSuspense fallback={<ModalLoader />}>
      <LazyAssetOfferModal {...props} />
    </SsrSuspense>
  )
}
