import React from "react"
import { format, isToday, isTomorrow } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { useTranslate } from "@/hooks/useTranslate"
import { useAcceptOfferDisabledReason_data$key } from "@/lib/graphql/__generated__/useAcceptOfferDisabledReason_data.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import {
  DATETIME_FORMAT_STRING,
  SHORT_TIME_FORMAT_STRING,
} from "../../../../../../constants/datetime"

export const useAcceptOfferDisabledReason = (
  dataKey: useAcceptOfferDisabledReason_data$key | null,
): React.ReactNode => {
  const t = useTranslate("assets")

  const acceptOfferDisabled = useFragment(
    graphql`
      fragment useAcceptOfferDisabledReason_data on AcceptOfferDisabledType {
        until
      }
    `,
    dataKey,
  )

  const getUntilTimestamp = (until: Date): string => {
    if (isToday(until)) {
      return t(
        "orders.acceptOffersDisabled.today",
        "{{shortTimestamp}} today",
        { shortTimestamp: format(until, SHORT_TIME_FORMAT_STRING) },
        { forceString: true },
      )
    }

    if (isTomorrow(until)) {
      return t(
        "orders.acceptOffersDisabled.tomorrow",
        "{{shortTimestamp}} tomorrow",
        { shortTimestamp: format(until, SHORT_TIME_FORMAT_STRING) },
        { forceString: true },
      )
    }

    return format(until, DATETIME_FORMAT_STRING)
  }

  const untilMessage = acceptOfferDisabled?.until
    ? t(
        "orders.acceptOffersDisabled.until",
        "Please wait until {{untilTimestamp}}. ",
        {
          untilTimestamp: getUntilTimestamp(
            dateFromISO8601(acceptOfferDisabled.until),
          ),
        },
        { forceString: true },
      )
    : ""

  const recentTransferReason = t(
    "orders.acceptOffersDisabled.recentTransfer.reason",
    "You cannot accept offers after a recent transfer. {{untilMessage}}{{learnMore}}",
    {
      untilMessage,
      learnMore: (
        <Link
          href="https://support.opensea.io/articles/8867002"
          onClick={e => e.stopPropagation()}
        >
          {t(
            "orders.acceptOffersDisabled.recentTransfer.learnMore",
            "Learn more",
          )}
        </Link>
      ),
    },
  )

  if (!acceptOfferDisabled) {
    return ""
  }

  return recentTransferReason
}
