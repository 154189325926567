/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Media, Text, classNames, Alert, Flex } from "@opensea/ui-kit"
import { intlFormat, isAfter } from "date-fns"
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"
import styled from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { ItemCell } from "@/components/assets/ItemCell"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { CollectionCell } from "@/components/collections/CollectionCell.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AcceptOfferButton } from "@/components/trade/AcceptOfferButton"
import { QuickBuyButton } from "@/components/trade/QuickBuyButton"
import { useIsQuickBuyEnabled } from "@/components/trade/QuickBuyButton/useIsQuickBuyEnabled.react"
import { IS_SERVER } from "@/constants/environment"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import {
  loadNextToLoadMore,
  ScrollingPaginator,
} from "@/design-system/ScrollingPaginator"
import { Table } from "@/design-system/Table"
import { Tooltip } from "@/design-system/Tooltip"
import { OrderStatus } from "@/features/account/components/OffersTable/OrderStatus"
import { useAcceptOfferDisabledReason } from "@/features/assets/components/AssetPage/components/OffersPanel/useAcceptOfferDisabledReason.react"
import { CancelOrderButton } from "@/features/cancel-orders/components/CancelOrderButton.react"
import { COMPROMISED_ACCOUNT_ACTION_DISABLED } from "@/features/settings/constants/messages"
import { ItemAddToCartButton } from "@/features/shopping-cart/components/ItemAddToCartButton"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { useScheduledOrderText } from "@/hooks/useScheduledOrderText"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickCounteroffer } from "@/lib/analytics/events/orderEvents"
import {
  Orders_data$data,
  Orders_data$key,
} from "@/lib/graphql/__generated__/Orders_data.graphql"
import {
  Orders_orders$data,
  Orders_orders$key,
} from "@/lib/graphql/__generated__/Orders_orders.graphql"
import {
  OrdersQuery,
  OrdersQuery$data,
  OrdersQuery$variables,
} from "@/lib/graphql/__generated__/OrdersQuery.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { getAssetUrl } from "@/lib/helpers/asset"
import { dateFromISO8601, useFromNow } from "@/lib/helpers/datetime"
import {
  MAX_DISPLAYED_DECIMAL_PLACES,
  bn,
  displayFiat,
  quantityDisplay,
} from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { captureNoncriticalError } from "@/lib/sentry"
import THEMES from "@/styles/themes"
import { NO_ASKS_YET_IMG, NO_BIDS_YET_IMG } from "../../constants"
import { themeVariant } from "../../styles/styleUtils"
import { AcceptOfferDisabledWarningIcon } from "./AcceptOfferDisabledWarningIcon.react"
import { ExpirationDate } from "./ExpirationDate"

const PAGE_SIZE = 10

type OrderSide = "bid" | "ask"

type Props = {
  className?: string
  footer?: React.ReactNode
  hideCta?: boolean
  isCurrentUser?: boolean
  ownedQuantity?: number
  mode: OrdersType
  side: OrderSide
  scrollboxClassName?: string
  renderSkeletonRows?: () => React.ReactNode
  variables: OrdersQuery$variables
}

type IRowCellContent = {
  cryptoPrice: GridCellContent
  usdPrice: GridCellContent
  floorDifference: GridCellContent
  quantity: GridCellString
  expiration: GridCellContent
  status: GridCellContent
  actions: GridCellJsx
  accountLink: GridCellContent
  assetOrCollection: GridCellJsx
  received: GridCellJsx
}

type GridCellContent = string | JSX.Element | null | undefined
type GridCellString = string | null | undefined
type GridCellJsx = JSX.Element | null | undefined

enum ColumnType {
  cryptoPrice,
  usdPrice,
  floorDifference,
  quantity,
  accountLink,
  expiration,
  status,
  assetOrCollection,
  received,
  actions,
}

export enum OrdersType {
  minimal = "minimal",
  full = "full",
  expanded = "expanded",
  listings = "listings",
}

// Put columns in order in left to right display order
const columnRegistry = {
  [OrdersType.minimal]: [
    ColumnType.cryptoPrice,
    ColumnType.usdPrice,
    ColumnType.quantity,
    ColumnType.floorDifference,
    ColumnType.expiration,
    ColumnType.accountLink,
    ColumnType.actions,
  ],
  [OrdersType.full]: [
    ColumnType.cryptoPrice,
    ColumnType.usdPrice,
    ColumnType.quantity,
    ColumnType.floorDifference,
    ColumnType.expiration,
    ColumnType.accountLink,
    ColumnType.actions,
  ],
  [OrdersType.expanded]: [
    ColumnType.assetOrCollection,
    ColumnType.cryptoPrice,
    ColumnType.usdPrice,
    ColumnType.floorDifference,
    ColumnType.quantity,
    ColumnType.accountLink,
    ColumnType.expiration,
    ColumnType.received,
    ColumnType.status,
    ColumnType.actions,
  ],
  [OrdersType.listings]: [
    ColumnType.assetOrCollection,
    ColumnType.cryptoPrice,
    ColumnType.usdPrice,
    ColumnType.floorDifference,
    ColumnType.expiration,
    ColumnType.actions,
  ],
}

type OrderType = Omit<Orders_orders$data, " $fragmentType">[0]

const getCollectionFloorPriceInEth = (order: OrderType) => {
  const collection = order.item.collection
  return collection?.statsV2.floorPrice?.eth ?? undefined
}

const getTakerAsset = (order: OrderType, data: Orders_data$data | null) => {
  if (order.orderType !== "CRITERIA") {
    return order.item
  }

  return data?.criteriaTakerAsset
}

const TakerAction = ({
  order,
  trigger,
  data,
}: {
  order: OrderType
  trigger: React.ReactElement
  data: Orders_data$data | null
}) => {
  const t = useTranslate("orders")
  const activeAccount = useActiveAccount()
  const isCompromised = activeAccount?.isCompromised ?? false
  const assetId = getTakerAsset(order, data)?.relayId
  const listingTime = dateFromISO8601(order.openedAt)
  const isScheduledOrderInFuture = isAfter(listingTime, new Date())
  const isCtaDisabled = isScheduledOrderInFuture || isCompromised

  const scheduledOrderText = useScheduledOrderText(
    listingTime,
    order.side === "ASK" ? "buy" : "sell",
  )

  const isSeaportEnabled = useIsSeaportEnabled(order.item.chain?.identifier)

  const showAddToCartButton = isSeaportEnabled && order.side === "ASK"

  const isSingleAssetContext = !!data?.criteriaTakerAsset

  const acceptOfferDisabledReason = useAcceptOfferDisabledReason(
    !isSingleAssetContext ? order.item.acceptOfferDisabled ?? null : null,
  )

  if (isSeaportEnabled && showAddToCartButton) {
    return <Block>{trigger}</Block>
  }

  if (order.side === "BID") {
    return (
      <Modal
        trigger={openAcceptOfferDisabledModal => (
          <AcceptOfferButton
            criteriaAsset={data?.criteriaTakerAsset}
            order={order}
            source="offers table"
            trigger={openAcceptOfferModal => (
              <Block
                onClick={() => {
                  if (!order.item.acceptOfferDisabled) {
                    openAcceptOfferModal()
                  } else if (!isSingleAssetContext) {
                    openAcceptOfferDisabledModal()
                  }
                }}
              >
                {trigger}
              </Block>
            )}
          />
        )}
      >
        <Modal.Header>
          <Modal.Header.Title>
            {t("acceptOfferDisabled.recentTransfer.title", "Recent transfer")}
          </Modal.Header.Title>
        </Modal.Header>
        <Modal.Body>
          <Block paddingBottom="16px">
            <Text.Body size="small">{acceptOfferDisabledReason}</Text.Body>
          </Block>
        </Modal.Body>
      </Modal>
    )
  }

  if (!isCtaDisabled && assetId) {
    return <QuickBuyButton order={order} />
  } else {
    return (
      <Tooltip
        content={
          isScheduledOrderInFuture
            ? scheduledOrderText
            : isCompromised
            ? COMPROMISED_ACCOUNT_ACTION_DISABLED
            : ""
        }
        disabled={!isCtaDisabled}
      >
        <span>{trigger}</span>
      </Tooltip>
    )
  }
}

const Actions = ({
  order,
  hideCta,
  ownedQuantity,
  data,
  onOrderCanceled,
  showAcceptOfferDisabledWarning,
}: {
  order: OrderType
  hideCta?: boolean
  ownedQuantity?: number
  data: Orders_data$data | null
  onOrderCanceled: () => void
  showAcceptOfferDisabledWarning: boolean
}) => {
  const t = useTranslate("orders")
  const { isActiveAccount } = useWallet()
  const isSeaportEnabled = useIsSeaportEnabled(order.item.chain?.identifier)
  const isQuickBuyEnabled = useIsQuickBuyEnabled(order)

  if (hideCta) {
    return null
  }

  const takerAsset = getTakerAsset(order, data)
  const takerAssetOwnedQuanity = ownedQuantity ?? takerAsset?.ownedQuantity ?? 0
  const bidItemQuantity =
    order.side === "BID" && takerAsset ? order.remainingQuantityType : undefined

  const isMaker = isActiveAccount(order.maker)
  const canTake = !order.taker || isActiveAccount(order.taker)

  const hasFulfillableQuantity =
    !bidItemQuantity || !bn(takerAssetOwnedQuanity).isZero()

  const showFulfillButton =
    order.orderType !== "ENGLISH" &&
    hasFulfillableQuantity &&
    !takerAsset?.isDelisted

  const listingTime = dateFromISO8601(order.openedAt)
  const isScheduledOrderInFuture = isAfter(listingTime, new Date())
  const isCtaDisabled = isScheduledOrderInFuture

  const privateListingUrl = `${getAssetUrl(order.item, "sell")}?taker=${
    order.maker.address
  }`

  const showExtraOptions = order.side === "BID" && takerAsset
  const showAddToCartButton = isSeaportEnabled && order.side === "ASK"

  const renderTakerButton = () => {
    if (order.side === "BID") {
      return (
        <AcceptOfferButton
          criteriaAsset={data?.criteriaTakerAsset}
          order={order}
          size="small"
          source="offers table"
        />
      )
    }

    if (showAddToCartButton) {
      if (isQuickBuyEnabled) {
        return (
          <Block>
            <QuickBuyButton order={order} size="small" />
          </Block>
        )
      }
      return (
        <Block>
          <ItemAddToCartButton iconOnly order={order} variant="compact" />
        </Block>
      )
    }

    return (
      <TakerAction
        data={data}
        order={order}
        trigger={
          <Button disabled={isCtaDisabled} size="small" variant="secondary">
            {order.side === "ASK"
              ? t("taker.buttonCTA.buy", "Buy")
              : t("taker.buttonCTA.accept", "Accept")}
          </Button>
        }
      />
    )
  }

  const asset = order.item.__typename === "AssetType" ? order.item : null

  const isSingleAssetContext = !!data?.criteriaTakerAsset

  return (
    <Flex className="justify-end px-0 py-2">
      {isMaker ? (
        <CancelOrderButton
          dataKey={order}
          gaslessCancelEligibleOrders={
            order.invalidationReason !== "GASLESS_CANCEL"
          }
          onOrderCanceled={onOrderCanceled}
        />
      ) : !showFulfillButton ? null : canTake ? (
        showExtraOptions ? (
          <Block marginLeft="8px">
            <Media greaterThanOrEqual="md">
              <Flex className="items-center gap-2">
                {order.orderType !== "CRITERIA" && (
                  <Button
                    href={privateListingUrl}
                    size="small"
                    variant="secondary"
                    onClick={() => trackClickCounteroffer()}
                  >
                    {t("options.taker.counter", "Counter")}
                  </Button>
                )}
                {renderTakerButton()}
                {showAcceptOfferDisabledWarning && (
                  <AcceptOfferDisabledWarningIcon asset={asset} />
                )}
              </Flex>
            </Media>
            <Media lessThan="md">
              <Dropdown
                appendTo={IS_SERVER ? undefined : document.body}
                content={({ List, Item, close }) => (
                  <List>
                    <TakerAction
                      data={data}
                      order={order}
                      trigger={
                        <Item
                          disabled={
                            !!order.item.acceptOfferDisabled &&
                            isSingleAssetContext
                          }
                          onClick={close}
                        >
                          <Flex
                            className={classNames(
                              "w-full",
                              // simulate disabled state but allow tapping to open more info modal
                              order.item.acceptOfferDisabled &&
                                !isSingleAssetContext
                                ? "opacity-40"
                                : undefined,
                            )}
                          >
                            <Item.Avatar icon="task_alt" />
                            <Item.Content>
                              <Item.Title>
                                {t("options.accept", "Accept")}
                              </Item.Title>
                            </Item.Content>
                          </Flex>
                          <Item.Side>
                            {!isSingleAssetContext && (
                              <Alert.Icon color="warning" value="error" />
                            )}
                          </Item.Side>
                        </Item>
                      }
                    />
                    {order.orderType !== "CRITERIA" && (
                      <Item
                        href={privateListingUrl}
                        onClick={() => {
                          trackClickCounteroffer()
                          close()
                        }}
                      >
                        <Item.Avatar icon="swap_horiz" />
                        <Item.Content>
                          <Item.Title>
                            {t("options.counter", "Counter")}
                          </Item.Title>
                        </Item.Content>
                      </Item>
                    )}
                  </List>
                )}
              >
                {/* Margin ensures on-click shadow is not clipped. */}
                <Button className="mr-1" icon="more_vert" variant="secondary" />
              </Dropdown>
            </Media>
          </Block>
        ) : (
          renderTakerButton()
        )
      ) : (
        <Tooltip
          content={
            order.taker.address ? (
              <div>
                <div className="Orders--tooltip-header">
                  {t("tooltip.header.privateListing", "Private Listing")}
                </div>
                <div>
                  {t(
                    "tooltip.reservedListing",
                    "This listing is reserved for {{address}}.",
                    { address: truncateAddress(order.taker.address) },
                  )}
                </div>
              </div>
            ) : (
              t("tooltip.privateListing", "Private listing")
            )
          }
        >
          <span>
            <Button disabled variant="secondary">
              {t("buyButton", "Buy")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Flex>
  )
}

const OrdersBase = ({
  className,
  footer,
  hideCta,
  isCurrentUser,
  ownedQuantity,
  mode = OrdersType.minimal,
  side,
  scrollboxClassName,
  data: dataKey,
  variables,
  renderSkeletonRows,
}: Props & { data: OrdersQuery$data | null }) => {
  const t = useTranslate("orders")
  const { takerAssetIsOwnedBy, maker, makerAssetBundle, takerAssetBundle } =
    variables

  const showMinimalQuantity = !makerAssetBundle && !takerAssetBundle

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment(
      graphql`
        fragment Orders_data on Query
        @argumentDefinitions(
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 10 }
          excludeMaker: { type: "IdentityInputType" }
          isExpired: { type: "Boolean" }
          isInactive: { type: "Boolean" }
          isValid: { type: "Boolean" }
          includeInvalidBids: { type: "Boolean" }
          maker: { type: "IdentityInputType" }
          makerArchetype: { type: "ArchetypeInputType" }
          makerAssetIsPayment: { type: "Boolean" }
          takerArchetype: { type: "ArchetypeInputType" }
          takerAssetCollections: { type: "[CollectionSlug!]" }
          takerAssetIsOwnedBy: { type: "IdentityInputType" }
          takerAssetIsPayment: { type: "Boolean" }
          sortAscending: { type: "Boolean" }
          sortBy: { type: "OrderSortOption" }
          makerAssetBundle: { type: "BundleSlug" }
          takerAssetBundle: { type: "BundleSlug" }
          expandedMode: { type: "Boolean", defaultValue: false }
          isBid: { type: "Boolean", defaultValue: false }
          filterByOrderRules: { type: "Boolean", defaultValue: false }
          includeCriteriaOrders: { type: "Boolean", defaultValue: false }
          # This is used to prevent the query from complaining due to an invalid AssetRelayId even though includeCriteriaTakerAsset is false
          criteriaTakerAssetId: {
            type: "AssetRelayID"
            defaultValue: "QXNzZXRUeXBlOi0x"
          }
          includeCriteriaTakerAsset: { type: "Boolean", defaultValue: false }
          isSingleAsset: { type: "Boolean", defaultValue: false }
        )
        @refetchable(queryName: "OrdersPaginationQuery") {
          criteriaTakerAsset: asset(asset: $criteriaTakerAssetId)
            @include(if: $includeCriteriaTakerAsset) {
            ownedQuantity(identity: {})
            decimals
            isDelisted
            relayId
            ...asset_url
            ...AcceptOfferButton_asset
            ...AcceptOfferDisabledWarningIcon_asset
          }
          orders(
            after: $cursor
            excludeMaker: $excludeMaker
            first: $count
            isExpired: $isExpired
            isInactive: $isInactive
            isValid: $isValid
            includeInvalidBids: $includeInvalidBids
            maker: $maker
            makerArchetype: $makerArchetype
            makerAssetIsPayment: $makerAssetIsPayment
            takerArchetype: $takerArchetype
            takerAssetCollections: $takerAssetCollections
            takerAssetIsOwnedBy: $takerAssetIsOwnedBy
            takerAssetIsPayment: $takerAssetIsPayment
            sortAscending: $sortAscending
            sortBy: $sortBy
            makerAssetBundle: $makerAssetBundle
            takerAssetBundle: $takerAssetBundle
            filterByOrderRules: $filterByOrderRules
            includeCriteriaOrders: $includeCriteriaOrders
          ) @connection(key: "Orders_orders") {
            edges {
              node {
                ...Orders_orders
                  @arguments(
                    expandedMode: $expandedMode
                    isBid: $isBid
                    includeCriteriaTakerAsset: $includeCriteriaTakerAsset
                    isSingleAsset: $isSingleAsset
                  )
              }
            }
          }
        }
      `,
      dataKey as Orders_data$key | null,
    )

  const renderFromNow = useFromNow()

  const renderFloorDifference = (
    collectionFloorPriceInEth: string,
    order: OrderType,
  ) => {
    const floorPriceValue = bn(collectionFloorPriceInEth)
    const ratio = bn(order.perUnitPriceType.eth).div(floorPriceValue)
    const diff = ratio.minus(1)
    const percentageDiff = diff.times(100)

    return (
      <Flex>
        <Tooltip
          content={`${t(
            "floorDifference.collectionFloorPrice",
            "Collection floor price",
          )}: ${floorPriceValue.toFixed(MAX_DISPLAYED_DECIMAL_PLACES)} ETH`}
        >
          <Flex className="cursor-pointer">
            <Text className="ml-1" size="small">
              {percentageDiff.isZero()
                ? t("floorDifference.diff.atFloor", "At floor")
                : `${percentageDiff.abs().toFixed(0)}% ${
                    percentageDiff.isNegative()
                      ? t("floorDifference.diff.below", "below")
                      : t("floorDifference.diff.above", "above")
                  }`}
            </Text>
          </Flex>
        </Tooltip>
      </Flex>
    )
  }

  const getRowCellContent = (order: OrderType): IRowCellContent => {
    const remainingQuantity = order.remainingQuantityType

    const rowCellContent: IRowCellContent = {
      cryptoPrice: undefined,
      usdPrice: undefined,
      floorDifference: undefined,
      quantity: quantityDisplay(remainingQuantity),
      expiration: <ExpirationDate alwaysRelative dataKey={order} />,
      accountLink: <AccountLink dataKey={order.maker} variant="no-image" />,
      status: undefined,
      assetOrCollection:
        order.orderType === "CRITERIA" && order.criteria ? (
          <CollectionCell
            collection={order.criteria.collection}
            trait={order.criteria.trait}
          />
        ) : (
          // TODO(janclarin): Fix displaying random assets for collection offers.
          <ItemCell item={order.item} />
        ),
      actions: (
        <Actions
          data={data}
          hideCta={hideCta}
          order={order}
          ownedQuantity={ownedQuantity}
          showAcceptOfferDisabledWarning={!variables.includeCriteriaTakerAsset}
          onOrderCanceled={() => {
            clearCache()
            refetch(variables, { fetchPolicy: "network-only" })
          }}
        />
      ),
      received: (
        <Tooltip
          content={intlFormat(dateFromISO8601(order.openedAt), {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        >
          <span>{renderFromNow(dateFromISO8601(order.openedAt))}</span>
        </Tooltip>
      ),
    }

    const floorPrice = getCollectionFloorPriceInEth(order)
    if (
      (side === "bid" || mode === OrdersType.listings) &&
      floorPrice !== undefined
    ) {
      rowCellContent.floorDifference = renderFloorDifference(floorPrice, order)
    }

    rowCellContent.cryptoPrice = (
      <OrderPrice order={order} symbolVariant="both" variant="perUnit" />
    )

    rowCellContent.usdPrice =
      side === "ask" ? (
        <OrderUsdPrice order={order} variant="perUnit" />
      ) : (
        <Text size="small">{displayFiat(order.perUnitPriceType.usd)}</Text>
      )

    rowCellContent.status = (
      <OrderStatus
        invalidationReasonAsTooltip
        orderKey={order}
        showStatus={isCurrentUser && !takerAssetIsOwnedBy}
      />
    )

    return rowCellContent
  }

  const getCellClassNames = (column: ColumnType) => {
    const classNames: { [key: number]: string } = {
      [ColumnType.actions]: "Orders--actions-column",
    }
    return classNames[column]
  }

  const renderRow = (order: OrderType) => {
    try {
      const rowCellContent: IRowCellContent = getRowCellContent(order)

      const row = (
        <Table.Row key={order.relayId}>
          {/* TODO: there is currently no nice way to conditionally render cells based on the headers. Needs refactoring */}
          {columnRegistry[mode].map(column => {
            let rowCellContentMatch: GridCellContent = <></>
            switch (column) {
              case ColumnType.cryptoPrice:
                rowCellContentMatch = rowCellContent.cryptoPrice
                break
              case ColumnType.usdPrice:
                rowCellContentMatch = rowCellContent.usdPrice
                break
              case ColumnType.floorDifference:
                if (side === "ask" && mode !== OrdersType.listings) {
                  return null
                }
                rowCellContentMatch = rowCellContent.floorDifference
                break
              case ColumnType.quantity:
                if (mode == OrdersType.minimal && !showMinimalQuantity) {
                  return null
                }
                if (mode === OrdersType.expanded && side !== "bid") {
                  return null
                }
                rowCellContentMatch = rowCellContent.quantity
                break
              case ColumnType.expiration:
                rowCellContentMatch = rowCellContent.expiration
                break
              case ColumnType.accountLink:
                if (mode === OrdersType.expanded && side === "bid") {
                  return null
                }
                rowCellContentMatch = rowCellContent.accountLink
                break
              case ColumnType.actions:
                rowCellContentMatch = rowCellContent.actions
                break
              case ColumnType.status:
                rowCellContentMatch = rowCellContent.status
                break
              case ColumnType.assetOrCollection:
                rowCellContentMatch = rowCellContent.assetOrCollection
                break
              case ColumnType.received:
                rowCellContentMatch = rowCellContent.received
                break
              default:
                throw new UnreachableCaseError(column)
            }

            return (
              <Table.Cell
                className={getCellClassNames(column)}
                key={`${order.relayId}-${ColumnType[column]}`}
              >
                {rowCellContentMatch}
              </Table.Cell>
            )
          })}
        </Table.Row>
      )

      return row
    } catch (error) {
      captureNoncriticalError(error)
      return <></>
    }
  }

  const renderEmptyTable = () => {
    return (
      <div className="Orders--empty">
        <div>
          <Flex className="m-0">
            <StyledImage
              alt=""
              height={100}
              objectFit="cover"
              src={side === "bid" ? NO_BIDS_YET_IMG : NO_ASKS_YET_IMG}
              width={136}
            />
          </Flex>
          <div className="Orders--no-data-text" data-testid="no-data-text">
            {side === "bid"
              ? t("emptyTable.noOffers.", "No offers yet")
              : t("emptyTable.noListings", "No listings yet")}
          </div>
        </div>
      </div>
    )
  }

  const getTableHeaders = (): Record<OrdersType, string[]> => {
    const showStatus = isCurrentUser && !takerAssetIsOwnedBy

    const minimal = [
      t("headers.minimal.price", "Price"),
      t("headers.minimal.usdPrice", "USD Price"),
      ...(showMinimalQuantity
        ? [t("headers.minimal.quantity", "Quantity")]
        : []),
      t("headers.minimal.expiration", "Expiration"),
      t("headers.minimal.from", "From"),
      "",
    ]
    const full = [
      t("headers.full.unitPrice", "Unit Price"),
      t("headers.full.usdUnitPrice", "USD Unit Price"),
      t("headers.full.quantity", "Quantity"),
      t("headers.full.expiration", "Expiration"),
      t("headers.full.from", "From"),
      "",
    ]

    const expanded = [
      t("headers.expanded.offer", "Offer"),
      t("headers.expanded.unitPrice", "Unit Price"),
      t("headers.expanded.usdUnitPrice", "USD Unit Price"),
      side === "bid"
        ? t("headers.expanded.quantity", "Quantity")
        : t("headers.expanded.from", "From"),
      t("headers.expanded.expiration", "Expiration"),
      side === "bid" && maker
        ? t("headers.expanded.made", "Made")
        : t("headers.expanded.received", "Received"),
      showStatus ? t("headers.expanded.status", "Status") : "",
      "",
    ]

    const listings = [
      t("headers.listings.item", "Item"),
      t("headers.listings.unitPrice", "Unit Price"),
      t("headers.listings.usdUnitPrice", "USD Unit Price"),
      t("headers.listings.floorDifference", "Floor Difference"),
      t("headers.listings.expiration", "Expiration"),
      "",
    ]

    if (side === "bid") {
      const floorDifferenceColumnName = t(
        "headers.floorDifference",
        "Floor Difference",
      )
      const minimalFloorDifferenceIndex = showMinimalQuantity ? 3 : 2
      minimal.splice(minimalFloorDifferenceIndex, 0, floorDifferenceColumnName)
      full.splice(3, 0, floorDifferenceColumnName)
      expanded.splice(3, 0, floorDifferenceColumnName)
    }

    return { minimal, full, expanded, listings }
  }

  const orders = useFragment<Orders_orders$key>(
    graphql`
      fragment Orders_orders on OrderV2Type
      @argumentDefinitions(
        expandedMode: { type: "Boolean", defaultValue: false }
        isBid: { type: "Boolean", defaultValue: false }
        includeCriteriaTakerAsset: { type: "Boolean", defaultValue: false }
        isSingleAsset: { type: "Boolean", defaultValue: false }
      )
      @relay(plural: true) {
        isValid
        isCancelled
        openedAt
        orderType
        remainingQuantityType
        maker {
          address
          ...AccountLink_data
          ...wallet_accountKey
        }
        payment {
          relayId
          symbol
        }
        item {
          __typename
          relayId
          chain {
            identifier
          }
          ... on AssetType {
            ...asset_url
            decimals
            ownedQuantity(identity: {}) @skip(if: $isSingleAsset)
            isDelisted
            acceptOfferDisabled {
              __typename
              ...useAcceptOfferDisabledReason_data
                @skip(if: $includeCriteriaTakerAsset)
            }
            ...AcceptOfferDisabledWarningIcon_asset
              @skip(if: $includeCriteriaTakerAsset)
          }
          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                    isDelisted
                    ownedQuantity(identity: {})
                    decimals
                  }
                }
              }
            }
          }
        }
        relayId
        side
        taker {
          address
          ...AccountLink_data
          ...wallet_accountKey
        }
        invalidationReason
        perUnitPriceType {
          eth
          usd
        }
        ...OrderPrice
        ...OrderUsdPrice
        item @include(if: $isBid) {
          ... on AssetType {
            collection {
              statsV2 {
                floorPrice {
                  eth
                }
              }
            }
          }
          ... on AssetBundleType {
            bundleCollection: collection {
              statsV2 {
                floorPrice {
                  eth
                }
              }
            }
          }
        }
        item @include(if: $expandedMode) {
          ...ItemCell_data
        }
        ...CancelOrderButton_data
        ...ExpirationDate_data
        criteria @include(if: $isBid) {
          collection {
            ...CollectionCell_collection
          }
          trait {
            ...CollectionCell_trait
          }
        }
        ...ItemAddToCartButton_order
        ...QuickBuyButton_order
        ...useIsQuickBuyEnabled_order
        ...AcceptOfferButton_order @arguments(skipOwnedQuantity: $isSingleAsset)
        # The fragment spreads below allow orders from
        #   this table to be added to the trade station
        ...useFulfillSemiFungibleOrders_orders
        ...BuyNowButton_orders
        ...OrderStatus_order
      }
    `,
    getNodes(data?.orders),
  ).filter(
    order => !order.isCancelled && !bn(order.remainingQuantityType).isZero(),
  )

  const headers = getTableHeaders()

  const { theme } = useTheme()

  const ordersTable =
    data && !orders.length ? (
      renderEmptyTable()
    ) : (
      <Block className={scrollboxClassName} maxHeight="332px" overflowX="auto">
        <Table
          headers={headers[mode]}
          maxColumnWidths={
            mode === OrdersType.expanded
              ? [
                  360,
                  "auto",
                  "auto",
                  "auto",
                  160,
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                ]
              : ["auto", "auto", "auto", "auto", 160, "auto"]
          }
          renderHeader={({ Header, header, index }) => (
            <Header
              // Special casing so the header doens't bleed through when sticky
              backgroundColor={
                theme === "light" ? THEMES[theme].colors.base2 : "#1B1B1B"
              }
              className={getCellClassNames(columnRegistry[mode][index])}
              key={`${header || index}ColumnHeader`}
            >
              {header}
            </Header>
          )}
        >
          {data
            ? orders.map(order => renderRow(order))
            : renderSkeletonRows?.()}
        </Table>
        <ScrollingPaginator
          intersectionOptions={{ rootMargin: "512px" }}
          isFirstPageLoading={!data}
          page={{
            loadMore: count => loadNextToLoadMore({ loadNext, count }),
            isLoading: () => isLoadingNext,
            hasMore: () => hasNext,
          }}
          size={PAGE_SIZE}
        />
      </Block>
    )

  return (
    <DivContainer className={className}>
      {ordersTable}
      {footer}
    </DivContainer>
  )
}

export const Orders = (props: Props) => {
  return (
    <SsrSuspense fallback={<OrdersBase data={null} {...props} />}>
      <LazyOrders {...props} />
    </SsrSuspense>
  )
}

const LazyOrders = (props: Props) => {
  const data = useLazyLoadQuery<OrdersQuery>(
    graphql`
      query OrdersQuery(
        $cursor: String
        $count: Int = 10
        $excludeMaker: IdentityInputType
        $isExpired: Boolean
        $isValid: Boolean
        $includeInvalidBids: Boolean
        $isInactive: Boolean
        $maker: IdentityInputType
        $makerArchetype: ArchetypeInputType
        $makerAssetIsPayment: Boolean
        $takerArchetype: ArchetypeInputType
        $takerAssetCollections: [CollectionSlug!]
        $takerAssetIsOwnedBy: IdentityInputType
        $takerAssetIsPayment: Boolean
        $sortAscending: Boolean
        $sortBy: OrderSortOption
        $makerAssetBundle: BundleSlug
        $takerAssetBundle: BundleSlug
        $expandedMode: Boolean = false
        $isBid: Boolean = false
        $filterByOrderRules: Boolean = false
        $includeCriteriaOrders: Boolean = false
        $criteriaTakerAssetId: AssetRelayID = "QXNzZXRUeXBlOi0x"
        $includeCriteriaTakerAsset: Boolean = false
        $isSingleAsset: Boolean = false
      ) {
        ...Orders_data
          @arguments(
            cursor: $cursor
            count: $count
            excludeMaker: $excludeMaker
            isExpired: $isExpired
            isValid: $isValid
            includeInvalidBids: $includeInvalidBids
            isInactive: $isInactive
            maker: $maker
            makerArchetype: $makerArchetype
            makerAssetIsPayment: $makerAssetIsPayment
            takerArchetype: $takerArchetype
            takerAssetIsOwnedBy: $takerAssetIsOwnedBy
            takerAssetCollections: $takerAssetCollections
            takerAssetIsPayment: $takerAssetIsPayment
            sortAscending: $sortAscending
            sortBy: $sortBy
            makerAssetBundle: $makerAssetBundle
            takerAssetBundle: $takerAssetBundle
            expandedMode: $expandedMode
            isBid: $isBid
            filterByOrderRules: $filterByOrderRules
            includeCriteriaOrders: $includeCriteriaOrders
            criteriaTakerAssetId: $criteriaTakerAssetId
            includeCriteriaTakerAsset: $includeCriteriaTakerAsset
            isSingleAsset: $isSingleAsset
          )
      }
    `,
    props.variables,
  )

  return <OrdersBase data={data} {...props} />
}

const StyledImage = styled(Image)`
  ${themeVariant({
    variants: {
      dark: {
        opacity: 0.5,
      },
    },
  })}
`

const DivContainer = styled(Block)`
  .Orders--empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;

    .Orders--no-data-text {
      display: flex;
      font-size: 16px;
      margin-top: 4px;
      justify-content: center;
    }
  }

  .Orders--status-text {
    color: ${props => props.theme.colors.error};
  }

  .Orders--tooltip-price {
    color: ${props => props.theme.colors.fog};
    margin: 0 0.3em 0 0.15em;
  }

  .Orders--actions-column {
    padding: 8px;
    // Special casing so the button doens't bleed through when sticky
    background-color: ${props =>
      props.theme.type == "light" ? props.theme.colors.base2 : "#1B1B1B"};
    position: sticky;
    right: 0;
  }
`
