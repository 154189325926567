import { useMemo } from "react"
import { isExpiring } from "@/features/account/utils/status"
import { useTranslate } from "@/hooks/useTranslate"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { StatusIndicatorType } from "../types"

export const useStatusIndicator = () => {
  const t = useTranslate("account")
  const statusIndicatorLabels: Record<StatusIndicatorType, string> = useMemo(
    () => ({
      valid: t("offersTable.statusIndicator.active", "Active"),
      invalid: t("offersTable.statusIndicator.inactive", "Inactive"),
      expiring: t("offersTable.statusIndicator.expiring", "Expiring"),
    }),
    [t],
  )

  return { statusIndicatorLabels }
}

export const getStatusIndicatorVariant = (
  isValid: boolean,
  closedAt: string | null,
): StatusIndicatorType => {
  const closedAtDate = closedAt ? dateFromISO8601(closedAt) : null

  return isValid && closedAtDate
    ? isExpiring(closedAtDate)
      ? "expiring"
      : "valid"
    : "invalid"
}
