/**
 * @generated SignedSource<<153572188f854fe3ffa00d5f8960b086>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAcceptOfferDisabledReason_data$data = {
  readonly until: string | null;
  readonly " $fragmentType": "useAcceptOfferDisabledReason_data";
};
export type useAcceptOfferDisabledReason_data$key = {
  readonly " $data"?: useAcceptOfferDisabledReason_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAcceptOfferDisabledReason_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAcceptOfferDisabledReason_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "until",
      "storageKey": null
    }
  ],
  "type": "AcceptOfferDisabledType",
  "abstractKey": null
};

(node as any).hash = "232173cf8472abb0bb2705f1faa32fce";

export default node;
