import React from "react"
import { Text, Alert } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { IS_SERVER } from "@/constants/environment"
import { Tooltip } from "@/design-system/Tooltip"
import { useAcceptOfferDisabledReason } from "@/features/assets/components/AssetPage/components/OffersPanel/useAcceptOfferDisabledReason.react"
import { AcceptOfferDisabledWarningIcon_asset$key } from "@/lib/graphql/__generated__/AcceptOfferDisabledWarningIcon_asset.graphql"

type AcceptOfferDisabledWarningIconProps = {
  asset: AcceptOfferDisabledWarningIcon_asset$key | null
}

export const AcceptOfferDisabledWarningIcon = ({
  asset: assetDataKey,
}: AcceptOfferDisabledWarningIconProps) => {
  const asset = useFragment(
    graphql`
      fragment AcceptOfferDisabledWarningIcon_asset on AssetType {
        acceptOfferDisabled {
          ...useAcceptOfferDisabledReason_data
        }
      }
    `,
    assetDataKey,
  )

  const disabledReason = useAcceptOfferDisabledReason(
    asset?.acceptOfferDisabled ?? null,
  )

  if (!asset?.acceptOfferDisabled) {
    return null
  }

  return (
    <Tooltip
      appendTo={IS_SERVER ? undefined : document.body}
      content={
        <Text.Body size="small" weight="semibold">
          {disabledReason}
        </Text.Body>
      }
      interactive
      placement="top-start"
    >
      <Alert.Icon color="warning" value="error" />
    </Tooltip>
  )
}
