import { getTrackingFn } from "../utils"

type ShareObjectType =
  | "account"
  | "item"
  | "bundle"
  | "links"
  | "collection"
  | "drop"
  | "deal"
type ShareActionType = "link" | "facebook" | "twitter"

export const trackShareDropdownOpened = getTrackingFn<{
  object: ShareObjectType
}>("share dropdown opened")

export const trackShareDropdownShareAction = getTrackingFn<{
  object: ShareObjectType
  action: ShareActionType
}>("share dropdown share action")
