/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { graphql, useFragment } from "react-relay"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { useFormattedExpirationDate } from "@/components/orders/ExpirationDate/utils"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import {
  OrderStatus_order$data,
  OrderStatus_order$key,
} from "@/lib/graphql/__generated__/OrderStatus_order.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { EM_DASH } from "@/lib/helpers/stringUtils"
import { getStatusIndicatorVariant } from "./hooks/useStatusIndicator"

type Props = {
  orderKey: OrderStatus_order$key
  showStatus?: boolean
  invalidationReasonAsTooltip?: boolean
}

export const OrderStatus = ({
  orderKey,
  showStatus,
  invalidationReasonAsTooltip = false,
}: Props) => {
  const order = useFragment<OrderStatus_order$key>(
    graphql`
      fragment OrderStatus_order on OrderV2Type {
        isValid
        closedAt
        invalidationReason
        payment {
          symbol
        }
        ...OrderPrice
      }
    `,
    orderKey,
  )

  const t = useTranslate("account")

  const closedAtDate = order.closedAt ? dateFromISO8601(order.closedAt) : null

  const { formattedExpirationDate } = useFormattedExpirationDate(closedAtDate, {
    maxExpirationDate: undefined,
    alwaysRelative: true,
  })

  const indicatorStatusVariant = getStatusIndicatorVariant(
    order.isValid,
    closedAtDate?.toISOString() || null,
  )

  const renderStatus = (order: OrderStatus_order$data) => {
    if (!showStatus) {
      return <>{EM_DASH}</>
    }

    if (indicatorStatusVariant === "valid") {
      return <>{t("rowCell.content.valid", "Valid")}</>
    }

    if (indicatorStatusVariant === "expiring") {
      return (
        <>
          {t("expiring.tooltip", "Expiring {{formattedExpirationDate}}", {
            formattedExpirationDate,
          })}
        </>
      )
    }

    let tooltipContent: React.ReactNode
    let shorterTooltipContent: React.ReactNode = (
      <>{t("rowCell.invalidationReason.short.nullInvalid", "Invalid")}</>
    )
    switch (order.invalidationReason) {
      case null:
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.nullInvalid",
              "This offer is invalid. This could be due to not having at least {{price}} in your wallet or currency approval has been revoked.",
              {
                price: (
                  <OrderPrice
                    className="Orders--tooltip-price"
                    color="theme.colors.text.secondary"
                    isInline
                    order={order}
                    symbolVariant="raw"
                  />
                ),
              },
            )}
          </div>
        )
        break
      case "ERC20_APPROVAL_REVOKED":
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.erc20ApprovalRevoked",
              "This offer is invalid due to {{symbol}} approval being revoked",
              { symbol: order.payment.symbol },
            )}
          </div>
        )
        shorterTooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.short.erc20ApprovalRevoked",
              "{{symbol}} approval revoked",
              { symbol: order.payment.symbol },
            )}
          </div>
        )
        break
      case "ASSET_OWNERSHIP_UPDATE":
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.assetOwnershipUpdate",
              "You no longer own this item",
            )}
          </div>
        )

        break
      case "NOT_EXECUTABLE":
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.notExecutable",
              "You have multiple offers in this collection and don't have enough balance to cover all of them",
            )}
          </div>
        )

        break
      case "INSUFFICIENT_BALANCE":
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.insufficientBalanceWallet",
              "This offer is invalid until there is at least {{price}} in your wallet",
              {
                price: (
                  <OrderPrice
                    className="Orders--tooltip-price"
                    color="theme.colors.text.secondary"
                    isInline
                    order={order}
                    symbolVariant="raw"
                  />
                ),
              },
            )}
          </div>
        )
        shorterTooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.short.insufficientBalanceWallet",
              "Not enough funds",
            )}
          </div>
        )
        break
      case "GASLESS_CANCEL":
        tooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.gaslessCancel",
              "This gasless cancellation is awaiting finalization.",
            )}
          </div>
        )
        shorterTooltipContent = (
          <div>
            {t(
              "rowCell.invalidationReason.short.gaslessCancel",
              "Gasless cancel in progress",
            )}
          </div>
        )
        break
    }

    const shorterInvalidStatusContent = (
      <div className="Orders--status-text">{shorterTooltipContent}</div>
    )

    return tooltipContent && invalidationReasonAsTooltip ? (
      <Tooltip content={tooltipContent}>
        <>{shorterInvalidStatusContent}</>
      </Tooltip>
    ) : (
      shorterInvalidStatusContent
    )
  }

  return renderStatus(order)
}
