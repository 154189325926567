import React from "react"
import { useFragment } from "react-relay"
import { useContextSelector } from "use-context-selector"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Button } from "@/design-system/Button"
import { CancelOrderContext } from "@/features/cancel-orders/context/CancelOrderContext.react"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrderButton_data$key } from "@/lib/graphql/__generated__/CancelOrderButton_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useIsGaslessCancellationEnabled } from "../hooks/useIsGaslessCancellationEnabled"
import { CancelOrdersActionModal } from "./CancelOrdersActionModal/CancelOrdersActionModal.react"

type CancelOrderButtonProps = {
  dataKey: CancelOrderButton_data$key
  disabled?: boolean
  gaslessCancelEligibleOrders?: boolean
  onOrderCanceled?: (params: { isOrderV2Enabled: boolean }) => unknown
  trigger?: (
    open: () => unknown,
    props: { shouldCancelOnChain: boolean },
  ) => React.ReactNode
}

export const CancelOrderButton = ({
  dataKey,
  disabled,
  gaslessCancelEligibleOrders = true,
  onOrderCanceled,
  trigger,
}: CancelOrderButtonProps) => {
  const { showErrorMessage } = useToasts()
  const t = useTranslate("orders")

  const { openModal, closeModal } = useGlobalModal()

  const ordersAwaitingFinalization = useContextSelector(
    CancelOrderContext,
    v => v.ordersAwaitingFinalization,
  )

  const order = useFragment<CancelOrderButton_data$key>(
    graphql`
      fragment CancelOrderButton_data on OrderV2Type {
        relayId
        item {
          __typename
          chain {
            identifier
          }
        }
        ...useIsGaslessCancellationEnabled_order
      }
    `,
    dataKey,
  )

  const { relayId: orderRelayId, item } = order

  const { isEnabled: isGaslessCancellationEnabled } =
    useIsGaslessCancellationEnabled({
      order,
    })

  const connectedAddress = useConnectedAddress()
  if (!connectedAddress) {
    // Hide button if there is no active account
    return null
  }

  const chain = item.chain.identifier

  const isCancellationInProgress = ordersAwaitingFinalization.has(orderRelayId)
  const shouldCancelOnChain =
    !gaslessCancelEligibleOrders || isCancellationInProgress

  const openCancellationModal = () => {
    const onEnd = () => {
      closeModal()
      onOrderCanceled?.({ isOrderV2Enabled: true })
    }

    const onError = (error?: Error) => {
      showErrorMessage(
        error?.message ||
          t("cancelOrder.error", "Something went wrong while cancelling."),
      )
      closeModal()
    }

    openModal(
      <CancelOrdersActionModal
        gaslessCancelEligibleOrders={
          isGaslessCancellationEnabled && !shouldCancelOnChain
        }
        orders={[orderRelayId]}
        onEnd={onEnd}
        onError={onError}
      />,
      {},
    )
  }

  return (
    <NetworkUnsupportedGate chainIdentifier={chain} shouldAuthenticate>
      {({ handleIfNotSupported }) =>
        trigger ? (
          trigger(handleIfNotSupported(openCancellationModal), {
            shouldCancelOnChain,
          })
        ) : (
          <Button
            disabled={disabled}
            variant="secondary"
            onClick={handleIfNotSupported(openCancellationModal)}
          >
            {shouldCancelOnChain
              ? t("cancelOrder.cancelNow", "Cancel now")
              : t("cancelOrder.cta", "Cancel")}
          </Button>
        )
      }
    </NetworkUnsupportedGate>
  )
}
