import React from "react"
import qs from "qs"
import { useCopyToClipboard } from "react-use"
import { OPENSEA_TWITTER_HANDLE, OPENSEA_LOGO_IMG } from "@/constants"
import { IS_SERVER } from "@/constants/environment"
import { Dropdown } from "@/design-system/Dropdown"
import { PopoverProps } from "@/design-system/Popover"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackShareDropdownOpened,
  trackShareDropdownShareAction,
} from "@/lib/analytics/events/shareEvents"
import { TwitterAnalytics } from "@/lib/analytics/twitter"

type Props = {
  children: React.ReactElement
  object:
    | "account"
    | "item"
    | "bundle"
    | "links"
    | "collection"
    | "drop"
    | "deal"
  url: string
  onTrigger?: PopoverProps["onTrigger"]
}

export const Share = ({ children, object, url, onTrigger }: Props) => {
  const t = useTranslate("components")
  const { origin } = useRouter()
  const { showSuccessMessage } = useToasts()
  const [_, copy] = useCopyToClipboard()

  const getFullUrl = () => {
    return `${origin}${url}`
  }

  return (
    <Dropdown
      appendTo={IS_SERVER ? undefined : document.body}
      content={({ close, List, Item }) => (
        <List>
          <Item
            onClick={() => {
              copy(getFullUrl())
              trackShareDropdownShareAction({ object, action: "link" })
              close()
              showSuccessMessage(t("share.success", "Link copied!"))
            }}
          >
            <Item.Avatar src={OPENSEA_LOGO_IMG} />
            <Item.Content>
              <Item.Title>{t("share.copyLink", "Copy link")}</Item.Title>
            </Item.Content>
          </Item>
          <Item
            href={`https://twitter.com/intent/tweet?${qs.stringify({
              text: `Check out ${
                object === "links"
                  ? "the official links of "
                  : "this " + object + " on"
              } OpenSea`,
              url: getFullUrl(),
              via: OPENSEA_TWITTER_HANDLE,
            })}`}
            onClick={() => {
              trackShareDropdownShareAction({ object, action: "twitter" })
            }}
          >
            <Item.Avatar src="/static/images/logos/twitter.svg" />
            <Item.Content>
              <Item.Title>
                {t("share.shareOnTwitter", "Share on Twitter")}
              </Item.Title>
            </Item.Content>
            <TwitterAnalytics />
          </Item>
        </List>
      )}
      placement="bottom-end"
      onTrigger={(...args) => {
        trackShareDropdownOpened({ object })

        onTrigger?.(...args)
      }}
    >
      {children}
    </Dropdown>
  )
}
