/**
 * @generated SignedSource<<a48278482546d35391f3776169dbb4f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderInvalidationReason = "ACCOUNT_DISABLED" | "ASSET_DISABLED" | "ASSET_OWNERSHIP_UPDATE" | "ASSET_REQUIRES_APPROVAL" | "CANCEL_TRANSACTION" | "COLLECTION_DISABLED" | "ERC20_APPROVAL_REVOKED" | "EXECUTION_REVERTED_ERROR" | "EXPIRED" | "GASLESS_CANCEL" | "INSUFFICIENT_BALANCE" | "MATCH_ORDERS_ERROR" | "NOT_EXECUTABLE" | "ON_CHAIN_VALIDATION_ERROR" | "ORDER_MALFORMED" | "PREMATCHED" | "TRAIT_OFFERS_DISABLED" | "TRANSACTION_SUBMISSION_ERROR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderStatus_order$data = {
  readonly closedAt: string | null;
  readonly invalidationReason: OrderInvalidationReason | null;
  readonly isValid: boolean;
  readonly payment: {
    readonly symbol: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OrderPrice">;
  readonly " $fragmentType": "OrderStatus_order";
};
export type OrderStatus_order$key = {
  readonly " $data"?: OrderStatus_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderStatus_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStatus_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invalidationReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "5b551f6971ffaec5e431abd285d3a4f4";

export default node;
